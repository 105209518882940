import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { CgProfile } from 'react-icons/cg';
import { IoIosArrowDown } from 'react-icons/io';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const RutValidationSchema = Yup.object().shape({
  rut: Yup.string()
    .matches(
      /^\d{1,2}\d{3}\d{3}[-][0-9kK]{1}$/,
      { message: 'El RUT debe seguir el formato XXXXXXXX-X' },
    )
    .required('RUT es necesario.')
    .test('Is valid RUT', 'El RUT ingresado no es válido', (rut) => Fn.validaRut(rut)),
});

// https://gist.github.com/donpandix/f1d638c3a1a908be02d5
const Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  validaRut(rutCompleto: string) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    const tmp: Array<string> = rutCompleto.split('-');
    let digv: string | number = Number(tmp[1]) ? Number(tmp[1]) : tmp[1];
    const rut = Number(tmp[0]);
    if (digv === 'K') digv = 'k';
    return (Fn.dv(rut) === String(digv));
  },
  dv(rut: number) {
    let M = 2;
    let R = rut;
    let suma = 0;
    while (R !== 0) {
      const number = R % 10;
      suma += number * M; // 0 - 7

      R = Math.floor(R / 10);
      M += 1;

      if (M === 8) {
        M = 2;
      }
    }

    const ret = 11 - (suma % 11);
    if (ret === 11) {
      return '0';
    } if (ret === 10) {
      return 'k';
    }
    return String(ret);
  },
};

const colourStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: 'white',
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
    fontWeight: 'lighter',
    fontSize: '14px',
  }),
  menu: (base: any, state: any) => ({
    ...base,
    backgroundColor: 'white',
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
    fontWeight: 'lighter',
    fontSize: '14px',
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#80808060',
    fontWeight: 'lighter',
    fontSize: '14px',
  }),

};

const RutContainer = ({ setRutFromThis }: any) => (
  <div className="mainContainerRutPage">
    <p className="containerTitle">
      <CgProfile style={{ marginRight: '4%' }} size={45} />
      Datos de paciente
    </p>
    <p className="containerSubTitle">Ingrese datos del paciente que será atendido</p>
    <div className="inputContainer">
      <Formik
        initialValues={{
          documento: 'cedula',
          rut: '',
        }}
        validationSchema={RutValidationSchema}
        onSubmit={(values) => {
          setRutFromThis(values.rut);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          errors, touched, setFieldValue, values,
        }) => (
          <Form>
              <label className="labelForm">Documento de identificación</label>
              <Select
                defaultValue={{ value: 'cedula', label: 'Cédula de identidad' }}
                placeholder="Selecciona un documento"
                className="formSelector"
                styles={colourStyles}
                options={[
                  { value: 'cedula', label: 'Cédula de identidad' },
                  // { value: 'pasaporte', label: 'Pasaporte' },
                ]}
                onChange={(documento: any) => {
                  setFieldValue('documento', documento.value)
                }}
              />
            <label className="labelForm">{values.documento === 'cedula' ? 'RUT' : 'Número de pasaporte'}</label>
            <Field
              id="rut"
              name="rut"
              placeholder={values.documento === 'cedula' ? 'Ingrese RUT del paciente' : 'Ingrese número de pasaporte'}
              className="fieldForm"
              onChange={(e: any) => {
                const value = e.target.value.replace(/ /g, '').replace(/\./g, '');
                const newValue = value.replace('-', '');
                if (newValue.length <= 7) {
                  setFieldValue('rut', newValue);
                } else {
                  const newValue2 = newValue.replace(/ /g, '').replace('-', '');
                  setFieldValue('rut',
                    `${newValue2.substring(0, newValue2.length - 1)}-${newValue2.substring(newValue2.length - 1,
                      newValue2.length)}`);
                }
              }}
            />
            <p className="rutDescription">{values.documento === 'cedula' ? '*Ejemplo 12345678-9' : '*Solo para extranjeros sin cédula de identidad'}</p>
            {errors.rut && touched.rut
              ? (<div className="error">{String(errors.rut)}</div>)
              : null}
            <button className="rutSearchButton" type="submit">Continuar</button>
          </Form>
        )}
      </Formik>
    </div>
  </div>
);

export default RutContainer;
