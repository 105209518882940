import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/Confirmation.css';
import 'dayjs/locale/es';
import axios from 'axios';
import { useState } from 'react';
import profesionalIcon from '../../assets/images/Profesional_Verde.png'
import estetoscopioIcon from '../../assets/images/Estetoscopio_Verde.png'
import sucursallIcon from '../../assets/images/Sucursal_Verde.png'
import ubicacionIcon from '../../assets/images/Ubicación_Verde.png'
import { ClipLoader } from 'react-spinners';

const baseURL = process.env.REACT_APP_BASEURL;
const authorization = process.env.REACT_APP_AUTHORIZATION;

interface hourData {
  reservationCode: string,
  fecha: string,
  hora: string,
  profesional: string,
  prestacion: string,
  sucursal: string,
  status: string,
  address: string
}

interface Props {
  hourData: hourData,
  containerRef: React.MutableRefObject<any>
  rut: string,
  action: string
}

const ConfirmationInformation = ({ hourData, rut, action }: Props) => {
  const [status, setStatus] = useState(hourData.status);
  const [street, setStreet] = useState();
  const [isLoadingAnnulment, setIsLoadingAnnulment] = useState(false);

  const toHome = () => {
    window.location.replace('https://maiposalud.cl');
  };

  const changeStatusHora = (status: number) => {
    setIsLoadingAnnulment(true);
    axios.post(`${baseURL}/alma/external/v1/confirmationWeb`,
      {
        id_agendamiento: hourData.reservationCode,
        status,
        rut,
      },
      {
        headers: {
          Authorization: authorization,
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        setIsLoadingAnnulment(false);
        if (status == 1) {
          alert('Hora confirmada con éxito');
          setStatus('confirmado');
        } else {
          alert('Hora cancelada con éxito');
          setStatus('anulado');
        }
      }).catch((err) => { setIsLoadingAnnulment(false) });
  };

  const findFacilitieAdress = (sucursalName: string) => {
    axios.post(`${baseURL}/alma/external/pp/v1/facility`, '', {
      headers: {
        Authorization: authorization,
      },
    }).then((response: any) => {
      response.data.map((item: any) => {
        if (item.name == sucursalName) {
          setStreet(item.street);
        }
      });
    }).catch((err) => console.error(err));
  };

  findFacilitieAdress(hourData.sucursal);

  return (
    <div className={action == 'annulment' ? 'hourInfoAnnulment' : 'hourInfoConfirmation'}>
      <div className="title">Información sobre tu hora</div>
      <div className="subTitle">
        Estado de la reserva:
        {' '}
        {status}
      </div>
      <div className="dateInfo">
        {hourData.fecha}
        {' '}
        {hourData.hora}
      </div>
      <div className="dataContainerConfirmation">
        <div className="reservationData">
          <div className="title"><b>Datos de Atención</b></div>
          <div className="rowInfo">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
              <img src={profesionalIcon} alt='calendar' style={{ width: '100%', height: '100%' }} />
            </div>
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div className='dataLabelTitle'>
                Profesional
              </div>
              <div>
                {hourData.profesional}
              </div>
            </div>
          </div>
          <div style={{ borderTop: "1px solid #5bb543", margin: "0px 0", width: '83%', marginLeft: '13%' }} />
          <div className="rowInfo">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
              <img src={estetoscopioIcon} alt='calendar' style={{ width: '100%', height: '100%' }} />
            </div>
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div className='dataLabelTitle'>
                Prestación
              </div>
              <div>
                {hourData.prestacion}
              </div>
            </div>
          </div>
          <div style={{ borderTop: "1px solid #5bb543", margin: "0px 0", width: '83%', marginLeft: '13%' }} />
          <div className="rowInfo">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
              <img src={sucursallIcon} alt='calendar' style={{ width: '100%', height: '100%' }} />
            </div>
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div className='dataLabelTitle'>
                Sucursal
              </div>
              <div>
                {hourData.sucursal}
              </div>
            </div>
          </div>
          <div style={{ borderTop: "1px solid #5bb543", margin: "0px 0", width: '83%', marginLeft: '13%' }} />
          <div className="rowInfo">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%', padding: '2%' }}>
              <img src={ubicacionIcon} alt='calendar' style={{ width: '100%', height: '100%' }} />
            </div>
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div className='dataLabelTitle'>
                Dirección
              </div>
              <div>
                {street}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="buttonsConfirmation" >
        <button type="submit" onClick={toHome} style={{ width: '84%' }}>Volver al inicio</button>
        {status === 'no confirmado' && (
          <div className='extraButtons'>
            <button type="submit" className={action == 'annulment' ? 'buttonAnnulment' : ''}
              onClick={() => { changeStatusHora(2); }}>
              {isLoadingAnnulment ?
                <ClipLoader color='white' size={20} /> : 'Anular Hora'}
            </button>
            {action != 'annulment' &&
              <button type="submit" onClick={() => { changeStatusHora(1); }}>Confirmar Hora</button>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmationInformation;
